@import "~@aws-amplify/ui-react/styles.css"

[data-amplify-theme="default-theme"] 
  /* Typography */
  --amplify-font-family: 'Montserrat, sans-serif' !important
  --amplify-fonts-default-variable: 'Montserrat', sans-serif !important

  --amplify-components-button-primary-background-color: #8300A1 !important
  --amplify-components-button-primary-hover-background-color: #52008E !important
  --amplify-components-fieldcontrol-focus-border-color: var(--amplify-components-button-primary-background-color) !important
  --amplify-components-fieldcontrol-focus-box-shadow: rgb(131, 0, 161) 0px 0px 0px 1px !important
  --amplify-components-tabs-item-active-border-color: var(--amplify-components-button-primary-background-color) !important
  --amplify-components-tabs-item-hover-color: var(--amplify-components-button-primary-background-color) !important
  --amplify-components-button-font-weight: 700 !important

  --amplify-colors-primary-100: var(--amplify-colors-purple-100) !important
  --amplify-colors-primary-90: var(--amplify-colors-purple-90) !important
  --amplify-colors-primary-80: var(--amplify-colors-purple-80) !important
  --amplify-colors-primary-60: var(--amplify-colors-purple-60) !important
  --amplify-colors-primary-40: var(--amplify-colors-purple-40) !important
  --amplify-colors-primary-20: var(--amplify-colors-purple-20) !important
  --amplify-colors-primary-10: var(--amplify-colors-purple-10) !important

[data-amplify-authenticator] [data-amplify-footer]
  padding-top: 16px

.amplify-button
  font-weight:  600 !important

.amplify-tabs__item[aria-selected="false"]
  background-color: #FAFAFA

.amplify-tabs__panel
  padding-block: 0rem

.federated-sign-in-button
  font-weight: normal !important

.amplify-label
  display: none !important

input[type=checkbox]
  transform: scale(1.25)
  cursor: pointer

.amplify-flex .checkbox label
  color: #212353

[class*=amplify]
  font-family: inherit
