html, body  
  min-height: 100vh
  scroll-snap-type: y mandatory

body  
  font-family: Montserrat, sans-serif !important

@import "./site.sass"
@import "./app.sass"
@import "./amplify.sass"

.hidden
  display: none

table th
  color: inherit