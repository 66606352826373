@import "~bulma/sass/utilities/initial-variables"

// Config vars
// Nualang logo brand color: #FD461E
$nualang-primary: #3F51B5
$nualang-primary-invert: #fff

$primary: $nualang-primary
$primary-invert: $nualang-primary-invert
$body-color: #212353
$black: #2b2523
$content-heading-color: #212353

// remove default section padding
$section-padding: 0
$section-padding-medium: 0
$section-padding-large: 0
  
#nav-logo
  margin-top: 12px
  height: 40px

// Disable the fullhd breakpoint
$fullhd-enabled: false

.content p 
  line-height: 1.6
  font-size: 1.2rem

.content li
  line-height: 1.6
  font-size: 1.2rem

.terms p 
  line-height: 1.6
  font-size: 1rem

.terms li
  line-height: 1.6
  font-size: 1rem

.content blockquote 
  font-weight: 600
  border-left: 10px solid #A507AE !important
  background-color: #fff !important

.content p strong
  font-weight: 600

.content .taglist
  list-style: none
  margin-bottom: 0
  margin-left: 0
  margin-right: 1.5rem
  margin-top: 1.5rem
  display: flex
  flex-wrap: wrap
  justify-content: left
  align-items: center
  li
    padding: 0 2rem 1rem 0
    margin-bottom: 1.5rem
    margin-top: 0

.footer
  background-color: $nualang-primary !important

// Helper Classes
.full-width-image-container
  width: 100vw
  height: 400px
  position: relative
  left: 50%
  right: 50%
  margin: 2em -50vw
  background-size: cover
  background-position: bottom
  display: flex
  justify-content: center
  align-items: center

.hero-bg
  width: 100vw
  min-height: 870px
  background-size: cover

.hero-container
  padding-top: 150px

.hero-container-image
  display: none

.hero-container h1
  font-weight: bold
  font-size: 48px
  line-height: 59px
  margin-bottom: 1.5rem

.hero-container h4
  font-weight: 600
  font-size: 18px
  line-height: 22px
  margin-bottom: 1.5rem

.hero-container p
  font-size: 18px
  margin-bottom: 1.5rem

.full-width-image
    width: 100vw
    height: 400px
    background-size: cover
    background-position: bottom
    display: flex
    justify-content: center
    align-items: center

.btn
    display: inline-block
    padding: 12px 16px 10px
    font-size: 18px
    font-size: 1rem
    line-height: 1.25
    background-color: #fff
    border-radius: .25rem
    text-decoration: none
    font-weight: 700
    color: #CC3700
    text-align: center
    -webkit-box-shadow: inset 0 0 0 2px #CC3700
    box-shadow: inset 0 0 0 2px #f40
    -webkit-transition: all .15s ease
    transition: all .15s ease

.margin-top-0
  margin-top: 0 !important

.navbar-item .icon
  color: $primary
// Override for use of svg's from https://simpleicons.org/
.icon svg
  width: 1.5rem
  height: 1.5rem
  fill: currentColor
.navbar-brand .navbar-item.logo
  padding: 0 1rem
footer.footer
  padding: 3rem 0rem 0rem
  background-color: transparent

.modal-card-foot
  justify-content: flex-end !important

//Menu overrides
$menu-item-color: $white-ter
$menu-item-hover-color: $black !important
$menu-item-hover-background-color: $black
$menu-item-active-color: $nualang-primary-invert
$menu-item-active-background-color:	$nualang-primary-invert
$menu-list-border-left: 1px solid $nualang-primary-invert
$menu-label-color: $white-ter

.menu-label
  font-size: 1em !important
  text-align: left
.menu-list
  list-style: none !important
  text-align: left

// blog roll
.blog-list-item.is-featured
  background-color: #d6400033
.blog-list-item header
  display: flex
  margin-bottom: 1em
.blog-list-item .featured-thumbnail
  flex-basis: 35%
  margin: 0 1.5em 0 0


// @import "~bulma"
@import "~bulma/sass/utilities/_all"
@import "./bulma/base/_all"
@import "~bulma/sass/elements/_all"
@import "~bulma/sass/form/_all"
@import "~bulma/sass/components/_all"
@import "~bulma/sass/grid/_all"
@import "~bulma/sass/layout/_all"

// responsiveness
+tablet-only
  .blog-list-item .featured-thumbnail
    flex-basis: 50%

+mobile
  .blog-list-item header
    display: block
  .blog-list-item .featured-thumbnail
    text-align: center
    max-width: 70%
    margin: 0 0 1em

+touch
  .hero-bg
    background-image: none !important
    min-height: 0
  .hero-container
    padding: 3rem 0.75rem 0.75rem 0.75rem
  .hero-container-image
    display: block

