.bot-preview-dialogue
  flex: 1 1 auto
  overflow: auto
  height: 0
  border-right: 2px solid #ddd
  border-left: 2px solid #ddd

.react-codemirror2, .CodeMirror
  height: 100% !important
  font-family: 'Montserrat', sans-serif !important
  font-size: 1rem

.CodeMirror 
  padding: 0 !important

.cm-s-easymde .CodeMirror-scroll
  max-height: 300px

.SplitPane
  height: auto !important

.Resizer
  background: #000
  opacity: 0.2
  z-index: 1
  -moz-box-sizing: border-box
  -webkit-box-sizing: border-box
  box-sizing: border-box
  -moz-background-clip: padding
  -webkit-background-clip: padding
  background-clip: padding-box

.Resizer:hover
  -webkit-transition: all 2s ease
  transition: all 2s ease

.Resizer.horizontal
  height: 11px
  margin: -5px 0
  border-top: 5px solid rgba(255, 255, 255, 0)
  border-bottom: 5px solid rgba(255, 255, 255, 0)
  cursor: row-resize
  width: 100%

.Resizer.horizontal:hover
  border-top: 5px solid rgba(0, 0, 0, 0.5)
  border-bottom: 5px solid rgba(0, 0, 0, 0.5)

.Resizer.vertical
  width: 11px
  margin: 0 -5px
  border-left: 5px solid rgba(255, 255, 255, 0)
  border-right: 5px solid rgba(255, 255, 255, 0)
  cursor: col-resize

.Resizer.vertical:hover
  border-left: 5px solid rgba(0, 0, 0, 0.5)
  border-right: 5px solid rgba(0, 0, 0, 0.5)

.Resizer.disabled
  cursor: not-allowed

.Resizer.disabled:hover
  border-color: transparent

.language_name
  text-transform: capitalize
  